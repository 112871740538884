import React from 'react'

function FooterCopyright() {
  return (
    <div className=' text-center pt-3 pb-3 ' style={{borderTop:"1px solid rgba(210, 210, 210, .5)",background:'#fcfaf8',color:'#707070'}}>
      Copyright © 2025 Aayush.
    </div>
  )
}

export default FooterCopyright
